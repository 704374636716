import { AdsClick, ArrowDownward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { GrDown, GrUp } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import customToast from "../../Commen_Component/Toast/CustomToast";
import { CashApprovalList } from "../../Commen_Services/CashApprovalList.service";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { bool } from "aws-sdk/clients/signer";
import { fileURLToPath } from "url";
import { exit } from "process";
import { useLoading } from "../../Loader/LoadingContext";




interface InvoiceData {
    companyId: number;
    companyName: string;
    customerId: number;
    customerName: string;
    code: string;
    collectionId: number;
    collectionDetailsId: number;
    date: string;
    modeOfPayment: string;
    transactionAmount: number;
    invoiceNo: string;
    invoiceDate: string;
    totalAmount: number;
    adjmentAmount: number;
    adjustmentAmount: number;
    finalAmount: string;
    reason: string | null;
    is_updated: boolean;
    isApproved: boolean;
    isAllapproved: boolean;
}


const CashApproval: React.FC = () => {
    const location = useLocation();
    const [collectionData, setCollectionData] = useState(location.state);
    const [data, setData] = useState<InvoiceData[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const { setLoading } = useLoading();
    useEffect(() => {
        //const collectionData = location.state;
        const postData = {
            EmployeeId: collectionData.employeeId,
            Date: collectionData.date
        }

        CashApprovalList.getCashCollectionDetails(postData).then(user => {
            if (user.statusCode == 200) {
                if (user.status === false) {
                    //console.log(user.data);
                }
                else {
                    //console.log(user.data);
                    setData(user.data)
                    //data = user.data;
                    //setMasterData(user.data);
                }
            }
        });
    }, [collectionData, refresh]);

    const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
    const [expandedCollections, setExpandedCollections] = useState<number[]>([]); // Tracks expanded collections
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationforApprove, setShowConfirmationforApprove] = useState(false);
    const [handleCancel, setCancel] = useState(false);
    const [adjustmentInputs, setAdjustmentInputs] = useState<Record<string, Record<string, number>>>({}); // Tracks adjustment inputs for each invoice
    const [reasonInputs, setReasonInputs] = useState<Record<string, Record<string, string>>>({});
    const [validationErrors, setValidationErrors] = useState<Record<string, Record<string, string>>>({}); // Validation errors
    const [validationReasonErrors, setValidationReasonErrors] = useState<Record<string, Record<string, string>>>({}); // Validation errors
    const [finalAmount, setFinalAmount] = useState<Record<string, string>>({});
    const [updatedInvoice, setUpdatedInvoice] = useState<Record<string, boolean>>({});
    const [updateData, setUpdateData] = useState<any>({});
    const [approveData, setApproveData] = useState<any>([]);

    // Extract unique companies
    //const companies = Array.from(new Set(data.map((item) => item.companyName)));

    // Group data by company -> customer -> collections
    // const groupedData = companies.map((companyName) => {
    //     const customers = data
    //         .filter((item) => item.companyName === companyName)
    //         .reduce((acc, item) => {
    //             const existingCustomer = acc.find((c) => c.customerId === item.customerId);
    //             if (!existingCustomer) {
    //                 acc.push({
    //                     customerId: item.customerId,
    //                     customerName: item.customerName,
    //                     customerCode: item.code,
    //                     collections: [],
    //                 });
    //             }
    //             const customer = acc.find((c) => c.customerId === item.customerId);
    //             customer?.collections.push(item);
    //             return acc;
    //         }, [] as any[]);
    //     return { companyName, customers };
    // });

    const groupedData = React.useMemo(() => {
        const companies = Array.from(new Set(data.map((item) => item.companyName)));
        // const initialAdjustmentInputs = data.reduce((acc, item) => {
        //     acc[item.invoiceNo] = item.adjustmentAmount;
        //     return acc;
        // }, {} as Record<string, number>);

        const initialAdjustmentInputs = data.reduce((acc, item) => {
            acc[item.collectionId] = {
              ...acc[item.collectionId],
              [item.collectionDetailsId]: item.adjustmentAmount,
            };
            return acc;
          }, {} as Record<string, Record<string, number>>);

        // const initialReasonInputs = data.reduce((acc, item) => {
        //     acc[item.invoiceNo] = String(item.reason);
        //     return acc;
        // }, {} as Record<string, string>);

        const initialReasonInputs = data.reduce((acc, item) => {
            acc[item.collectionId] = {
              ...acc[item.collectionId],
              [item.collectionDetailsId]: String(item.reason),
            };
            return acc;
          }, {} as Record<string, Record<string, string>>);

        console.log(initialAdjustmentInputs);
        setAdjustmentInputs(initialAdjustmentInputs);
        setReasonInputs(initialReasonInputs);
        return companies.map((companyName) => {
            const customers = data
                .filter((item) => item.companyName === companyName)
                .reduce((acc, item) => {
                    const existingCustomer = acc.find((c) => c.customerId === item.customerId);
                    if (!existingCustomer) {
                        acc.push({
                            customerId: item.customerId,
                            customerName: item.customerName,
                            customerCode: item.code,
                            isApproved: item.isApproved,
                            isAllapproved: item.isAllapproved,
                            collections: [],
                        });
                    }
                    const customer = acc.find((c) => c.customerId === item.customerId);
                    customer?.collections.push(item);
                    return acc;
                }, [] as any[]);


            return { companyName, customers };
        });
    }, [data]);
    

    const handleCompanyClick = (companyName: string) => {
        setSelectedCompany(selectedCompany === companyName ? null : companyName);
    };

    const toggleCollection = (collectionId: number) => {
        setExpandedCollections((prev) =>
            prev.includes(collectionId)
                ? prev.filter((id) => id !== collectionId) // Collapse
                : [...prev, collectionId] // Expand
        );
    };

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {
        console.log(updateData);
        handleUpdate(updateData.invoiceNo, updateData.updatedvalue,updateData.collectionData);
        setShowConfirmation(false);
        handleCancel ? setCancel(false) : setCancel(true);
    };

    const handleCloseForApprove = () => {
        setShowConfirmationforApprove(false);
    };

    const handleConfirmExitForApprove = () => {
        setLoading(true);
        handleApprove();
        setShowConfirmationforApprove(false);
        handleCancel ? setCancel(false) : setCancel(true);
    };

    const handleUpdatebutton = (invoiceNo: Number, updatedvalue: Number, totalAmount: Number, collectionDatax: any) => {
        setUpdateData({
            invoiceNo: invoiceNo,
            updatedvalue: updatedvalue,
            collectionData: collectionDatax
        });
        console.log(updatedvalue);
        
        const adjustmentAmount = Number(adjustmentInputs[collectionDatax.collectionId][String(invoiceNo)] || 0);
        if (adjustmentAmount > Number(totalAmount) ) {
            // Validation check before submitting
            // setValidationErrors((prev) => ({
            //     ...prev,
            //     [String(invoiceNo)]: `Entered Amount Is Greater Than Collected Amount`,
            // }));

            setValidationErrors(prevState => ({
                ...prevState,
                [collectionDatax.collectionId]: {
                  ...prevState[collectionDatax.collectionId],
                  [String(invoiceNo)]: `Entered Amount Is Greater Than Collected Amount`,
                },
              }));

            //return;
        }
        if(adjustmentAmount <= 0)
        {
            setValidationErrors(prevState => ({
                ...prevState,
                [collectionDatax.collectionId]: {
                  ...prevState[collectionDatax.collectionId],
                  [String(invoiceNo)]: `Entered Amount Is Greater Than Collected Amount`,
                },
              }));
        }
        if(!reasonInputs[collectionDatax.collectionId][String(invoiceNo)])
        {
            setValidationReasonErrors(prevState => ({
                ...prevState,
                [collectionDatax.collectionId]: {
                  ...prevState[collectionDatax.collectionId],
                  [String(invoiceNo)]: `Please Enter Reason`,
                },
              }));
        }
        if(Number(adjustmentInputs[collectionDatax.collectionId][String(invoiceNo)]) == collectionDatax.adjustmentAmount)
        {
                return;
        }
        if((adjustmentAmount < Number(totalAmount)) && reasonInputs[collectionDatax.collectionId][String(invoiceNo)] && (adjustmentAmount > 0))
        {
            setShowConfirmation(true);
        }
    }

    const handleApproveButton = (customerId: Number,collectionDetails: any) => {
        console.log(collectionDetails);
        const filteredDetails = collectionDetails
        .filter((item: any) => item.customerId === customerId && (item.isApproved === false || item.isApproved == null)) // Match by customerId
        .reduce((unique: any[], current: any) => {
            const isDuplicate = unique.some(
                (item) => item.collectionId === current.collectionId 
            );
            if (!isDuplicate) {
                unique.push({collectionId : current.collectionId,
                    approvedTransactionAmount: current.finalCollectionAmount   
                });
            }
            return unique;
        }, []);
        console.log(filteredDetails);
        setApproveData(filteredDetails)
        setShowConfirmationforApprove(true);
    }

    const handleApprove = async () => {
        try {
            const user = await CashApprovalList.approveCashCollection(approveData); 
            if (user.statusCode === 200) {
                if (!user.status) {
                    customToast.error(user.message);
                } else {
                    refresh ? setRefresh(false) : setRefresh(true);
                }
            } else {
                customToast.error(user.message);
            }
        } catch (error) {
            console.error("Error approving cash collection:", error);
            customToast.error("Something Went Wrong");
        } finally {
            setLoading(false);
        }
    }

    const handleInputChange = (collectionId: number, invoiceNo: string, value: string, totalAmount: number) => {
        const numericValue = Number(value.substring(2,value.length));

        if (numericValue >= totalAmount) {
            // Validation: Prevent amount larger than the total amount
            // setValidationErrors((prev) => ({
            //     ...prev,
            //     [invoiceNo]: `Entered Amount Is Greater Than Collected Amount`,
            // }));

            setValidationErrors(prevState => ({
                ...prevState,
                [collectionId]: {
                  ...prevState[collectionId],
                  [String(invoiceNo)]: `Entered Amount Is Greater Than Collected Amount`,
                },
              }));
        }
        // else if (numericValue == 0)
        // {
        //     setValidationErrors((prev) => ({
        //         ...prev,
        //         [invoiceNo]: `Entered Amount Is Must be Grater Than Zero`,
        //     }));
        // }
         else {
            // Clear error if the input is valid
            setValidationErrors((prev) => {
                const updatedErrors = { ...prev };
                updatedErrors[collectionId] && delete updatedErrors[collectionId][invoiceNo];
                return updatedErrors;
            });
        }

        // setAdjustmentInputs((prev) => ({
        //     ...prev,
        //     [invoiceNo]: Number.isNaN(numericValue) ? 0 : numericValue,
        // }));

        setAdjustmentInputs(prevState => ({
            ...prevState,
            [collectionId]: {
              ...prevState[collectionId],
              [invoiceNo]: Number.isNaN(numericValue) ? 0 : numericValue,
            },
          }));
    };

    const handlereasonInputChange = (collectionId: any, invoiceNo: string, value: string) => {
        
        if (value.length > 250) {
            // setValidationReasonErrors((prev) => ({
            //     ...prev,
            //     [invoiceNo]: `Maximum 250 Characters Is Allowed`,
            // }));

            setValidationReasonErrors(prevState => ({
                ...prevState,
                [String(collectionId)]: {
                  ...prevState[String(collectionId)],
                  [String(invoiceNo)]: `Maximum 250 Characters Is Allowed`,
                },
              }));
        }
        else
        {
            // setReasonInputs((prev) => ({
            //     ...prev,
            //     [invoiceNo]: value,
            // }));

            
            // setValidationReasonErrors((prev) => ({
            //     ...prev,
            //     [invoiceNo]: ``,
            // }));

            setValidationReasonErrors(prevState => ({
                ...prevState,
                [String(collectionId)]: {
                  ...prevState[String(collectionId)],
                  [String(invoiceNo)]: ``,
                },
              }));
        }

        setReasonInputs(prevState => ({
            ...prevState,
            [String(collectionId)]: {
              ...prevState[String(collectionId)],
              [String(invoiceNo)]: value,
            },
          }));
    };


    const handleUpdate = (invoiceNo: string, totalAmount: number, collectionDatax : any) => {
        console.log(updateData);
        const adjustmentAmount = Number(adjustmentInputs[collectionDatax.collectionId][invoiceNo] || 0);

        if (collectionDatax.isAdvanceCollection != 1 && adjustmentAmount > collectionDatax.totalAmount) {
            // Validation check before submitting
            // setValidationErrors((prev) => ({
            //     ...prev,
            //     [invoiceNo]: `Entered Amount Is Greater Than Collected Amount`,
            // }));

            setValidationErrors(prevState => ({
                ...prevState,
                [String(collectionDatax.collectionId)]: {
                  ...prevState[String(collectionDatax.collectionId)],
                  [String(invoiceNo)]: `Entered Amount Is Greater Than Collected Amount`,
                },
              }));
        }
        if((reasonInputs[collectionDatax.collectionId] ? !reasonInputs[collectionDatax.collectionId][String(invoiceNo)] : !reasonInputs[collectionDatax.collectionId]))
        {
            // setValidationReasonErrors((prev) => ({
            //     ...prev,
            //     [invoiceNo]: `Please Enter Reason`,
            // }));

            setValidationReasonErrors(prevState => ({
                ...prevState,
                [String(collectionDatax.collectionId)]: {
                  ...prevState[String(collectionDatax.collectionId)],
                  [String(invoiceNo)]: `Please Enter Reason`,
                },
              }));
        }
        else
        {
            const finalValue = (finalAmount[collectionDatax.collectionId] ? finalAmount[collectionDatax.collectionId] : collectionDatax.finalCollectionAmount) - adjustmentAmount;
            setFinalAmount((prev) => ({
                ...prev,
                [collectionDatax.collectionId]: finalValue,
            }));
            setUpdatedInvoice((prev) => ({
                ...prev,
                [invoiceNo]: true,
            }));
        
            var adjust = Number(collectionDatax.adjmentAmount) -
                (adjustmentInputs[collectionDatax.collectionId] ? adjustmentInputs[collectionDatax.collectionId][collectionDatax.collectionDetailsId] : collectionDatax.adjustmentAmount || 0);
            //console.log(adjust);
            const postData = {
                collectionDetailsId: collectionDatax.collectionDetailsId,
                reason: reasonInputs[collectionDatax.collectionId][String(invoiceNo)],
                //adjmentAmount:collectionDatax.transactionAmount - adjustmentAmount
                adjmentAmount: adjust
            }

            CashApprovalList.SaveorUpdate(postData).then(user => {
                if (user.statusCode == 200) {
                    if (user.status === false) {
                        //console.log(user.data);
                        customToast.Warning(user.message);
                    }
                    else {
                        //console.log(user.data);
                        refresh ? setRefresh(false) : setRefresh(true);
                        customToast.success(user.message);
                        //setData(user.data)
                        //data = user.data;
                        //setMasterData(user.data);
                    }
                }
            });
            
        }
    };

    return (
        <>
            <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><LibraryBooksIcon /> &nbsp;Collection Details</span>
                    </div>
                    <hr className="colorgraph" />
                </div>
            </div>
            <div style={{ padding: "5px 20px" }}>
                {/* Company List */}
                {groupedData.map((company) => (
                    <div key={company.companyName} style={{ marginBottom: "10px" }}>
                        {/* Company Header */}
                        <div
                            style={{
                                padding: "10px",
                                //background: "#f0f0f0",
                                cursor: "pointer",
                                borderBottom: "1px solid #ccc",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            onClick={() => handleCompanyClick(company.companyName)}
                        >
                            <div><strong style={{ color: 'var(--body-main-dark-color)',fontSize: '14px' }}>{company.companyName}</strong></div>
                            <div><strong style={{ color: 'var(--body-main-dark-color)',fontSize: '14px' }}>{selectedCompany === company.companyName ? <GrUp /> : <GrDown />}</strong></div>
                        </div>

                        {/* Customer List (Only visible if the company is expanded) */}
                        {selectedCompany === company.companyName && (
                            <div style={{ marginLeft: "5px", marginTop: "5px"}}>
                                {company.customers.map((customer: any) => (
                                    <>
                                    <div key={customer.customerId} style={{ marginBottom: "10px",marginTop: "5px",
                                        border: '1px solid #ddd',
                                        borderRadius: '8px',
                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.42)',
                                        background: 'rgb(221 230 241)',
                                        // boxShadow: '-2px 4px 12px 3px rgba(105,99,99,0.71)',
                                        //                         WebkitBoxShadow: '-2px 4px 12px 3px rgba(105,99,99,0.71)',
                                        //                         MozBoxShadow: '-2px 4px 12px 3px rgba(105,99,99,0.71)',
                                        
                                     }}
                                     
                                     
                                     >
                                        
                                        {/* Customer Header */}
                                        <div
                                            style={{
                                                padding: "0px 8px",
                                                //background: "#e0e0e0",
                                                marginBottom: "5px",
                                                //background: 'rgb(251 247 247)',
                                                background: 'rgb(221 230 241)'
                                                //border: "1px solid #bbb",
                                            }}
                                        >
                                            {/* <span 
                                            style={{
                                                transform: 'translate(-50%, -50%) rotate(-45deg)',
                                                backgroundColor: '#4caf50',
                                                position: 'absolute',
                                                color:'white',
                                                padding: '5px 10px',
                                                fontSize: '12px'
                                            }}>Approved</span> */}
                                            {/* {customer.customerName} - {customer.customerCode} */}

                                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '25px', paddingRight: '25px', fontSize: '13px' }}>
                                                <div style={{ paddingTop: '8px', width: '40%',}}><strong style={{ color: 'var(--body-main-dark-color)' }}>Customer Name : </strong> {customer.customerName}</div>
                                                <div style={{ paddingTop: '8px', width: '40%',}}><strong style={{ color: 'var(--body-main-dark-color)' }}>Customer Code : </strong> {customer.customerCode}</div>
                                                <div style={{ display: 'flex',width: '20%', justifyContent:'flex-end'}}><button disabled={customer.isAllapproved} className={`btn123`} style={{ padding: 10, backgroundColor: customer.isAllapproved ? 'var(--bg-green)' : 'var(--body-main-dark-color)', color: customer.isAllapproved ? 'var(--button-color-light)' : 'var(--button-color-light)', border:customer.isAllapproved ? '1px solid var(--bg-green)' : '1px solid var(--body-main-dark-color)' }} onClick={() => { handleApproveButton(customer.customerId, customer.collections) }}  >&nbsp;{customer.isAllapproved ? <>Approved</> : <>Approve</>}</button></div>
                                            </div>



                                            {/* Collection Details */}
                                            {customer.collections.reduce((acc: any, item: any) => {
                                                const existingCollection = acc.find(
                                                    (c: any) => c.collectionId === item.collectionId
                                                );
                                                if (!existingCollection) {
                                                    acc.push({
                                                        collectionId: item.collectionId,
                                                        date: item.date,
                                                        paymentMode: item.modeOfPayment,
                                                        transactionAmount: item.transactionAmount,
                                                        totalAmount: item.totalAmount,
                                                        finalAmount: item.finalAmount,
                                                        finalCollectionAmount: item.finalCollectionAmount,
                                                        isAdvanceCollection: item.isAdvanceCollection,
                                                        invoices: [],
                                                    });
                                                }
                                                const collection = acc.find(
                                                    (c: any) => c.collectionId === item.collectionId
                                                );
                                                collection?.invoices.push(item);
                                                return acc;
                                            }, []).map((collection: any) => (
                                                <>
                                                    <div
                                                        key={collection.collectionId}
                                                        style={{
                                                            marginLeft: "20px",
                                                            marginBottom: "10px",
                                                            padding: "5px",
                                                            //background: "#d0d0d0",
                                                            //border: "1px solid #aaa",
                                                        }}
                                                    >
                                                        {/* Collection Header */}
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                marginTop: '-25px',
                                                                fontSize: '13px'
                                                            }}
                                                        //onClick={() => toggleCollection(collection.collectionId)}
                                                        >
                                                            <p>
                                                                <strong style={{ color: 'var(--body-main-dark-color)' }}>Collection ID:</strong> {collection.collectionId}
                                                            </p>
                                                            <p>
                                                                <strong style={{ color: 'var(--body-main-dark-color)' }}>Collection Date & Time:</strong>{" "}
                                                                {/* {new Date(collection.date).toLocaleDateString()} */}
                                                                {collection.date ? new Date(collection.date).toLocaleDateString('en-GB',{
                                                                                                                        day: '2-digit',
                                                                                                                        month: '2-digit',
                                                                                                                        year: 'numeric',
                                                                                                                        hour: '2-digit',
                                                                                                                        minute: '2-digit',
                                                                                                                        hour12: true,
                                                                                                                        }) : ''}

                                                            </p>
                                                            <p>
                                                                <strong style={{ color: 'var(--body-main-dark-color)' }}>Payment Mode:</strong> {collection.paymentMode}
                                                            </p>
                                                            <p>
                                                                <strong style={{ color: 'var(--body-main-dark-color)' }}>Collected Amount:</strong> {collection.transactionAmount}
                                                            </p>
                                                            <p>
                                                                <strong style={{ color: 'var(--body-main-dark-color)' }}>Final Amount:</strong> {(collection.finalCollectionAmount)}
                                                            </p>
                                                        </div>
                                                        <div
                                                            style={{
                                                                padding: "10px 15px 10px 15px",
                                                                //background: "#f0f0f0",
                                                                //background: 'var(--white)',
                                                                background: 'rgb(245 245 245)',
                                                                //background: 'rgb(221 230 241)',
                                                                cursor: "pointer",
                                                                borderBottom: "1px solid #ccc",
                                                                borderLeft:"1px solid #ccc",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                borderRadius: '5px',
                                                                boxShadow: '-4px 5px 5px -1px rgba(163,150,150,0.71)',
                                                                WebkitBoxShadow: '-4px 5px 5px -1px rgba(163,150,150,0.71)',
                                                                MozBoxShadow: '-4px 5px 5px -1px rgba(163,150,150,0.71)',
                                                            }}

                                                            onClick={() => toggleCollection(collection.collectionId)}
                                                        > <div style={{ 
                                                            color: 'var(--body-main-dark-color)', 
                                                        fontWeight: 'bold', fontSize: '13px' }}>{collection.isAdvanceCollection == 1 ? <>Advance Collection</> : <>Invoice Details</> }</div>
                                                            <div>
                                                                {expandedCollections.includes(collection.collectionId)
                                                                    ? <GrUp />
                                                                    : <GrDown />}
                                                            </div>
                                                        </div>

                                                        {/* Collection Details */}
                                                        {expandedCollections.includes(collection.collectionId) && (
                                                            <div style={{display:'flex', justifyContent:'right',
                                                                
                                                            }}>
                                                                {/* Invoice Details */}
                                                                <table
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        width: "99%",
                                                                        borderCollapse: "collapse",
                                                                        boxShadow:'0px 5px 9px 0px rgba(87, 86, 86, 0.66)',
                                                                // WebkitBoxShadow:'0px 5px 9px 0px rgba(0,0,0,0.75)',
                                                                // MozBoxShadow:'0px 5px 9px 0px rgba(0,0,0,0.75)'
                                                                    }}
                                                                >
                                                                    <thead>
                                                                        <tr style={{ color: 'var(--body-main-dark-color)', backgroundColor: 'var(--body-main-light-color)', fontSize: '13px', fontWeight: 'bold' }}>
                                                                            
                                                                            {collection.isAdvanceCollection == false && (<td style={{ padding: "5px" }}>
                                                                                Invoice No
                                                                            </td>)}
                                                                            {collection.isAdvanceCollection == false && (<td style={{ padding: "5px" }}>
                                                                                Invoice Date 
                                                                            </td>)}
                                                                            {collection.isAdvanceCollection == false && (<td style={{ padding: "5px" }}>
                                                                                Invoice Amount 
                                                                            </td>)}
                                                                            <td style={{ padding: "5px" }}>
                                                                                Collected Amount
                                                                            </td>
                                                                            <td style={{ padding: "5px" }}>
                                                                                Adjustment Amount
                                                                            </td>
                                                                            <td style={{ padding: "5px" }}>
                                                                                Final Amount
                                                                            </td>
                                                                            <td style={{ padding: "5px" }}>
                                                                                Reason
                                                                            </td>
                                                                            <td style={{ padding: "5px" }}>
                                                                                Action
                                                                            </td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ background: 'var(--button-color-light)' }}>
                                                                        {collection.invoices.map((invoice: any) => (
                                                                            <tr key={invoice.invoiceNo} style={{ fontSize: '13px' }}>
                                                                                {collection.isAdvanceCollection == false && (
                                                                                    <td
                                                                                    style={{

                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    {invoice.invoiceNo}
                                                                                </td>
                                                                                )}
                                                                                {collection.isAdvanceCollection == false && (<td
                                                                                    style={{

                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    {invoice.invoiceDate ? new Date(invoice.invoiceDate).toLocaleDateString('en-GB',{
                                                                                                                        day: '2-digit',
                                                                                                                        month: '2-digit',
                                                                                                                        year: 'numeric'
                                                                                                                        }) : ''}
                                                                                </td>)}
                                                                                {collection.isAdvanceCollection == false && (
                                                                                    <td
                                                                                    style={{

                                                                                        padding: "5px",
                                                                                    }}
                                                                                    >
                                                                                    {invoice.totalAmount}
                                                                                </td>)}
                                                                                <td
                                                                                    style={{

                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    {invoice.adjmentAmount}
                                                                                </td>
                                                                                <td
                                                                                    style={{

                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    <div style={{ display: 'flex', height: '20px', flexDirection: 'row', }}>
                                                                                        {/* {invoice.adjustmentAmount} */}
                                                                                        <input type="text" style={{ minWidth: '50px', maxWidth:'80px' }}
                                                                                            disabled={invoice.isApproved}
                                                                                            value={"- " + adjustmentInputs[collection.collectionId][invoice.collectionDetailsId]}

                                                                                           // value={adjustmentInputs[invoice.invoiceNo] ? adjustmentInputs[invoice.invoiceNo] : invoice.adjustmentAmount}
                                                                                            //value={" - " + invoice.adjustmentAmount} 
                                                                                            onChange={(e) => handleInputChange(collection.collectionId,invoice.collectionDetailsId, e.target.value, invoice.adjmentAmount)} />

                                                                                        {validationErrors[collection.collectionId] && (
                                                                                            <p style={{ color: "red", fontSize: "10px", marginTop: '-2px' }}>
                                                                                                &nbsp;&nbsp;{validationErrors[collection.collectionId][invoice.collectionDetailsId]}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                                <td
                                                                                    style={{

                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    {/* {invoice.finalAmount} */}
                                                                                    {adjustmentInputs[collection.collectionId][invoice.collectionDetailsId] > 0 ? 
                                                                                    Number(invoice.adjmentAmount) -
                                                                                    (adjustmentInputs[collection.collectionId][invoice.collectionDetailsId] ? adjustmentInputs[collection.collectionId][invoice.collectionDetailsId] : invoice.adjustmentAmount || 0) 
                                                                                    : invoice.adjmentAmount}

                                                                                    {/* {Number(invoice.adjmentAmount) -
                                                                                        (adjustmentInputs[invoice.invoiceNo] ? adjustmentInputs[invoice.invoiceNo] : invoice.adjustmentAmount || 0)} */}
                                                                                </td>
                                                                                <td
                                                                                    style={{

                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    {/* {invoice.reason || "-"} */}
                                                                                    <input type="text"
                                                                                        //value={invoice.reason}
                                                                                        disabled={invoice.isApproved}
                                                                                        //disabled={updatedInvoice[invoice.invoiceNo] || invoice.is_Updated}
                                                                                        //value={reasonInputs[invoice.invoiceNo] || invoice.reason}
                                                                                        value={reasonInputs[collection.collectionId][invoice.collectionDetailsId]}
                                                                                        onChange={(e) => handlereasonInputChange(collection.collectionId,invoice.collectionDetailsId, e.target.value)}
                                                                                    />
                                                                                    {validationReasonErrors[collection.collectionId] && validationReasonErrors[collection.collectionId][invoice.collectionDetailsId]  && (
                                                                                            <p style={{ color: "red", fontSize: "12px", marginTop: '2px' }}>
                                                                                                &nbsp; {validationReasonErrors[collection.collectionId][invoice.collectionDetailsId]}
                                                                                            </p>
                                                                                        )}
                                                                                </td>
                                                                                <td
                                                                                    style={{

                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    {!invoice.isApproved && 
                                                                                    
                                                                                    <button className={`btn123`} style={{ backgroundColor: 'var(--body-main-dark-color)', color: 'var(--button-color-light)', fontSize: '11px' }}
                                                                                    //onClick={() => { setShowConfirmation(true) }}
                                                                                    disabled={invoice.isApproved}  
                                                                                    //disabled={updatedInvoice[invoice.invoiceNo] || invoice.is_Updated}
                                                                                    onClick={() => { handleUpdatebutton(invoice.collectionDetailsId, (Number(invoice.adjmentAmount) - (adjustmentInputs[collection.collectionId][invoice.collectionDetailsId])), Number(invoice.adjmentAmount),invoice) }}
                                                                                >&nbsp;Update</button>
                                                                                    }
                                                                                   
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    {/* <hr style={{backgroundColor: 'var(--body-main-dark-color)'}}/> */}
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                ))}

                {showConfirmation && (
                    <Popup contentStyle={{ maxHeight: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                        <div className="confirmation-modal" style={{ paddingBottom: 10 }}>
                            <p>Are you sure, you want to Update?</p>
                            <div className="canel_btns">
                                <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
                                <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Update</button>
                            </div>
                        </div>
                    </Popup>
                )}

                {showConfirmationforApprove && (
                    <Popup contentStyle={{ maxHeight: 105, width: 275, padding: 0 }} position='top center' open={showConfirmationforApprove} onClose={() => setShowConfirmationforApprove(false)}>
                        <div className="confirmation-modal" style={{ paddingBottom: 10 }}>
                            <p>Are you sure, you want to Approve?</p>
                            <div className="canel_btns">
                                <button className="btn123" style={{ marginRight: 10 }} onClick={handleCloseForApprove}>Cancel</button>
                                <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExitForApprove}>Yes, Approve</button>
                            </div>
                        </div>
                    </Popup>
                )}
            </div>
        </>

    );
};

export default CashApproval;
